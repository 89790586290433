import React from 'react';
import Link from 'gatsby-link';
//import { readingTime as readingTimeHelper } from '@tryghost/helpers';
import { Flex, Box } from '../Layout';
import colors from '../../utils/colors';
import { isLoggedIn, hasRole } from "../../utils/auth";

class PostCardOverlap extends React.Component {

    render() {
        let url = `/post/${this.props.post.slug}/`;
        if (isLoggedIn() && hasRole(["trial","general","advance"])) {
            url = `/app/post/${this.props.post.slug}/`;
        }
        //const readingTime = readingTimeHelper(this.props.post);
        var wrapOption = this.props.wrapOption;
        if (!wrapOption)
            wrapOption = ['wrap','wrap','wrap'];
        var width = [wrapOption[0] === 'wrap'?'100%':'50%', wrapOption[1] === 'wrap'?'100%':'50%', wrapOption[2] === 'wrap'?'100%':'50%'];
        var marg = [wrapOption[0] === 'wrap'?0:15, wrapOption[1] === 'wrap'?0:15, wrapOption[2] === 'wrap'?0:15];
        var fontSize = 'normal';
        if (this.props.fontSize)
            fontSize = this.props.fontSize;
        return (
            <React.Fragment>
            
            <Box mb={[30,30,30]}>
            <Flex wrap={wrapOption}>
            
            <Box width={width} mr={marg} >

                <div style={{
                    backgroundImage: `url(${this.props.post.feature_image?this.props.post.feature_image:this.props.defaultHeaderImage})`,
                    backgroundPosition: 'center',

                    position: 'relative', 
                    backgroundSize: 'cover',
                    width:'100%',
                    paddingTop: '56.25%'
                    }}>
                        <div style={{position: 'absolute', 
                            bottom: 0, 
                            left: 0, 
                            width: '100%',
                            minHeight: '17%',
                            backgroundColor: 'rgba(22,22,22,.4)',
                            color: colors.primary}}>
                                <header>
                                <Box p={'0.6em'}>
                                <Link to={url}><h2 className={`post-card-title-new post-card-title-${fontSize}-new post-card-title-overlap-new`}>{this.props.post.title}</h2></Link>
                                <span className="post-card-byline-date-new post-card-byline-date-overlap-new">
                                {(new Date(this.props.post.published_at)).toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:'en-US',{year: 'numeric',month: 'long',day: 'numeric'})}
                                </span> 
                                </Box>
                                </header>                               
                        </div>
                </div>                 
            </Box>
            {this.props.hideExcerpt?null:
            <Box width={width} ml={marg}>
           
           
                <section className="post-card-excerpt-new"><p className={`post-card-excerpt-${fontSize}-new`}>{this.props.post.excerpt}</p></section>
            </Box>}

            </Flex>
            </Box>
            
            </React.Fragment>
        );
    }
}
    
export default PostCardOverlap;