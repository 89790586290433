import React from 'react'
import { css } from 'emotion';
import Link from 'gatsby-link';

const paginationcss = css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4vw 0 0;
`;

const paginationa = css`
    display: inline-block;
    padding: 10px 15px;
    border: #c7d5d8 1px solid;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1em;
    border-radius: 0.6rem;
`;

const paginationLocation = css`
    position: absolute;
    left: 50%;
    width: 100px;
    margin-left: -50px;
    text-align: center;
    font-size: 1rem;
`;

const Pagination = ( props ) => {
    const currentPage = props.currentPage;
    const numberOfPages = props.numberOfPages;

    return (
        <nav className={paginationcss} role="navigation">
            <div>
                {numberOfPages > 1 && props.previousPagePath  && currentPage > 1 && (
                    <Link to={props.previousPagePath} className={paginationa}>Previous
                    
                    </Link>                    
                )}
            </div>
            {numberOfPages > 1 && <div className={paginationLocation}>Page {currentPage} of {numberOfPages}</div>}
            <div>
                {numberOfPages > 1 && props.nextPagePath && currentPage < numberOfPages && (
                    <Link to={props.nextPagePath} className={paginationa}>Next
                    
                    </Link>
                )}
            </div>
        </nav>
    );
}

export default Pagination;
