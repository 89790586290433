import React from 'react';
import Pagination from './pagination';
import { navigate } from "gatsby";
import { Flex, Box } from '../Layout';
import PostCard from './postCard';
import PostCardOverlap from './postCardOverlap';

class PostFeedListCompact extends React.Component {
    constructor(props) {
        super(props);
        if (props.pageContext) {
            this.state = {posts: props.posts, pagination:{page:props.pageContext.humanPageNumber, pages:props.pageContext.numberOfPages}};
            this.currentPage = props.pageContext.humanPageNumber;
        }
        else
            this.state = {posts: props.posts, pagination:null};        
        
        this.goPreviousPage = this.goPreviousPage.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        
    }

    componentDidMount() {
    }

    goPreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage --;
            navigate(this.props.pageContext.previousPagePath)
        }
    }

    goNextPage() {
        if (this.currentPage < this.state.pagination.pages) {
            this.currentPage ++;
            navigate(this.props.pageContext.nextPagePath)
        }
    }

    render() {
        return (
            <div>
                <Flex wrap={['wrap','wrap','wrap',this.props.fontSize === 'normal'?'nowrap':'wrap']}>
                <Box width={[1,1,1,this.props.fontSize === 'normal'?0.539:1]} mr={[25,0,this.props.fontSize === 'normal'?20:0]} ml={[25,0,0]}>
                    {this.state.posts.length > 0?
                <PostCardOverlap defaultHeaderImage={this.props.defaultHeaderImage?this.props.defaultHeaderImage:`/llassets/default1.jpg`} hideImage={this.props.hideImage} post={this.state.posts[0]} hideExcerpt={true} fontSize={this.props.fontSize} />: null}
                </Box>
                <Box width={[1,1,1,this.props.fontSize === 'normal'?0.461:1]} mr={[25,0,0]} ml={[25,0,0]}>
                    {this.state.posts.map((post, index) => (
                        <React.Fragment key={post.slug}>
                            {index > 0?
                            <PostCard defaultImgIdx={index + 1} hideImage={this.props.hideImage} post={post} hideExcerpt={true} fontSize={'vsmall'} showPrimaryTag={this.props.fontSize === 'normal'} />:
                            null}
                        </React.Fragment>
                    ))}
                </Box>
                </Flex>
                
                {this.state.pagination?
                <Pagination 
                    currentPage={this.state.pagination.page} 
                    numberOfPages={this.state.pagination.pages}
                    previousPageCallback={this.goPreviousPage}
                    nextPageCallback={this.goNextPage}/>: null}
            </div>

          );
    }
}
    
export default PostFeedListCompact;