import React from 'react';
import Pagination from './pagination';
import { navigate } from "gatsby";
import PostCard from './postCard';



class PostFeedColumn extends React.Component {
    constructor(props) {
        super(props);
        if (props.pageContext) {
            this.state = {posts: props.posts, pagination:{page:props.pageContext.humanPageNumber, pages:props.pageContext.numberOfPages}};
            this.currentPage = props.pageContext.humanPageNumber;
        }
        else
            this.state = {posts: props.posts, pagination:null};
        this.goPreviousPage = this.goPreviousPage.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        
    }

    componentDidMount() {
    }

    goPreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage --;
            navigate(this.props.pageContext.previousPagePath)
        }
    }

    goNextPage() {
        if (this.currentPage < this.state.pagination.pages) {
            this.currentPage ++;
            navigate(this.props.pageContext.nextPagePath)
        }
    }

    render() {
        return (
            <div>
                <section className="post-feed-new">
                
                {
                    this.state.posts.map((post) => (
                    <React.Fragment key={post.slug}>
                        
                        <article className="post-card-new">
                        <PostCard hideImage={this.props.hideImage} showPrimaryTag={true} post={post} fontSize={'small'} wrapOption={['wrap','wrap','wrap']} />
                        </article>
                    </React.Fragment>
                    ))
                }
                
                </section>
                {this.state.pagination?
                <Pagination 
                    currentPage={this.state.pagination.page} 
                    numberOfPages={this.state.pagination.pages}
                    previousPageCallback={this.goPreviousPage}
                    nextPageCallback={this.goNextPage}/>: null}
            </div>

          );
    }
}
    
export default PostFeedColumn;