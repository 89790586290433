import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from '../components/Layout';
import colors from '../utils/colors';
import PageWrapper from '../components/PageWrapper';
import PostFeedColumn from '../components/CMS/postFeedColumn';
import { MetaData } from '../components/common/meta';
import SubNav from '../components/Navigation/subNav';
import Link from 'gatsby-link';
import PostFeedListCompact from '../components/CMS/postFeedListCompact';
/**
* Main index page (home page)
*/

const getPostColumn = (page, posts, hideImage) => {
    if (posts && posts.length > 0)
        return (
            <>
            {page !== '' && 
                <div style={{ borderBottom: '1px solid #e0e0e0', marginBottom: '10px', boxSizing: 'border-box'}}>
                    <span>
                        <Link to={page.primary_tag?`/tag/${page.primary_tag.slug}`:`/allPostList/`}><h2 style={{textTransform: 'uppercase', margin: '0 20px 10px 0', display: 'inline'}}>{page.primary_tag?page.title:page}</h2><h5 style={{display: 'inline'}}>[Show All]</h5></Link>                        
                    </span>
                </div>}

                <PostFeedColumn hideImage={hideImage} posts={posts} pageContext={null} maxCardPerRow={[2,3,4]}/>
            </>
        );
    else return null;
}

const getPostListCompact = (page, posts) => {
    if (posts && posts.length > 0)
        return (
            <>
                <article className="post-card-new">
                <div style={{ borderBottom: '1px solid #e0e0e0', marginBottom: '20px', boxSizing: 'border-box'}}>
                    <span>
                    <Link to={`/tag/${page.primary_tag.slug}`}><h2  style={{textTransform: 'uppercase', margin: '0 20px 10px 0', display: 'inline'}}>{page.title}</h2><h5 style={{display: 'inline'}}>[Show All]</h5></Link>
                    </span>
                </div>                                            
                <PostFeedListCompact defaultHeaderImage={page.feature_image} hideImage={true} posts={posts} pageContext={null} fontSize={'vsmall'}/>
                </article>
            </>
        );
    else return null;
}

const Index = ({ data, location, pageContext }) => {


    const catPages = data.allGhostPage.edges.map((page) => {
        return page.node;
    })
    const posts = data.allGhostPost.edges.map((post) => {
        //console.log('***+*+* post:' + post.node.slug);
        return post.node;
    })
    /*
    posts.forEach((post, index ) => {
        if (!post.feature_image) {
            var postCatTag = post.tags.find(tag=> tag.feature_image);
            if (postCatTag)
                post.feature_image = postCatTag.feature_image;
        }
    });*/

    var catPosts = {};
    catPages.forEach(( page, index ) => {
        catPosts[index] = posts.filter(
            (post) => 
            {
                return (post.tags.find(tag => tag.slug === page.primary_tag.slug)) ;
            }
        ).sort(
            function(a, b) {
                if (b.published_at > a.published_at) 
                    return 1; 
                else 
                    return -1;
            }
        ).slice(0,4);

    })
    

    var latestPosts = posts.slice(0,4);
    var latestPosts2;
    if (posts.length > 4) {
        latestPosts2 = posts.slice(4,8);
    }
    var latestPosts3;
    if (posts.length > 4) {
        latestPosts3 = posts.slice(8,12);
    }    
    var featuredPosts = posts.sort(
        function(a, b) {
            if (b.featured && !a.featured)
                return 1;
            if (!b.featured && a.featured)
                return -1;            
            if (b.published_at > a.published_at) 
                return 1; 
            else 
                return -1;
        }
    ).slice(0,4);
    /*
    console.log('no. of post:' + posts.length);
    console.log('no. of featured post:' + featuredPosts.length);
    console.log('no. of latest post:' + latestPosts.length);*/
    return (
        <>
            <MetaData location={location} />

            <PageWrapper>
                <Box>
                <Box width={[1, 1, 2/3]} minWidth={[0,0,'1024px']} ml={[0,0,'auto']} mr={[0,0,'auto']}>
                    <Box mt={['4rem']}
                        mb={['1.5rem', '1.5rem', '1.5rem']}>    
                        <SubNav/>
                    </Box>
                    <Box mb={['1.5rem', '1.5rem', '1.5rem']}>
                    <header >
                        <PostFeedListCompact hideImage={false} posts={featuredPosts} pageContext={null} fontSize={'normal'}/>
                    </header>
                    </Box>


                    <Box
                        mb={['1.5rem', '1.5rem', '1.5rem']}
                        px={[3, 3, 0]}
                        color={colors.secondary}>
                        {getPostColumn('News', latestPosts, true)}
                        {
                            latestPosts2 && getPostColumn('', latestPosts2, true)
                        }
                        {
                            latestPosts3 && getPostColumn('', latestPosts3, true)
                        }
                        <section className="post-feed-new">
                            {getPostListCompact(catPages[0], catPosts[0])}
                            {getPostListCompact(catPages[1], catPosts[1])}
                            {getPostListCompact(catPages[3], catPosts[3])}
                        </section>              

                    </Box>
                
                </Box>
                </Box>
            </PageWrapper>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

export const pageQuery = graphql`
  query GhostPostQuery($posts: [String]!) {
        
    allGhostPost(filter: {id: {in: $posts}}, sort: {order: [DESC], fields: [published_at]}, limit: 300) {
        totalCount
        edges {
          node {
            excerpt
            feature_image
            featured
            title
            slug
            published_at
            reading_time
            primary_tag {
              name
              slug
            }
            tags {
              slug
              name
              visibility
              feature_image
            }
          }
        }
      }
    allGhostPage(filter: {tags: {elemMatch: {slug: {eq: "hash-news-cat"}}}}, sort: {fields: published_at, order: ASC}) {
        edges {
            node {
                id
                slug
                title
                primary_tag {
                    slug
                }
                feature_image
            }
        }
    }              
  }
`
