import React from 'react';
import Link from 'gatsby-link';
import { Tags } from '@tryghost/helpers-gatsby';
//import { readingTime as readingTimeHelper } from '@tryghost/helpers';
import { Flex, Box } from '../Layout';
import { isLoggedIn, hasRole } from "../../utils/auth";

class PostCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {defaultImgIdx : (this.props.defaultImgIdx?this.props.defaultImgIdx:Math.floor(Math.random() * 7) + 1)};
    }
    render() {
        let url = `/post/${this.props.post.slug}/`;
        if (isLoggedIn() && hasRole(['trial','general','advance'])) {
            url = `/app/post/${this.props.post.slug}/`;
        }
        //const readingTime = readingTimeHelper(this.props.post);
        var wrapOption = this.props.wrapOption;
        if (!wrapOption)
            wrapOption = ['wrap','nowrap','nowrap'];
        
        var showPrimaryTag = false;
        if (this.props.showPrimaryTag && this.props.post.tags && this.props.post.tags.length > 0)
            showPrimaryTag = this.props.showPrimaryTag;

        var fontSize = 'normal';
        if (this.props.fontSize)
            fontSize = this.props.fontSize;
        
        var lwidthRatio = 50;
        var bottomMargin = 30;
        var centreMargin = '15px';
        if (this.props.hideImage)
            lwidthRatio = 0;
        else if (fontSize !== 'normal') {
            lwidthRatio = 33;
            bottomMargin = 0;
            centreMargin = '7px';
        }
        if (fontSize === 'vsmall') 
            bottomMargin = [20, 20, null, 20];
        var marg = [wrapOption[0] === 'wrap'?0:centreMargin, wrapOption[1] === 'wrap'?0:centreMargin, wrapOption[2] === 'wrap'?0:centreMargin];
        var lwidth = [wrapOption[0] === 'wrap'?'100%':`${lwidthRatio}%`, wrapOption[1] === 'wrap'?'100%':`${lwidthRatio}%`, wrapOption[2] === 'wrap'?'100%':`${lwidthRatio}%`];
        var rwidth = [wrapOption[0] === 'wrap'?'100%':`${100 -lwidthRatio}%`, wrapOption[1] === 'wrap'?'100%':`${100 -lwidthRatio}%`, wrapOption[2] === 'wrap'?'100%':`${100 -lwidthRatio}%`];
        var imgHeight;
        if (fontSize === 'vsmall') 
            imgHeight = [null, null , null, 108];
        else if (fontSize === 'small') 
            imgHeight = [null, null , 140, 170];
        return (
            <React.Fragment>
            
            <Box mb={bottomMargin}>
            <Flex wrap={wrapOption}>
            
            {!this.props.hideImage && 
            <Box height={imgHeight} width={lwidth} mr={marg} mb={wrapOption[2] === 'wrap'?'10px':0}>
                <div className={`post-card-image-new post-card-image-${fontSize}-new`} >
                <Link to={url}>
            {this.props.post.feature_image? 
                    <img  src={this.props.post.feature_image}/>:
                    <img  src={`/llassets/default${this.state.defaultImgIdx}.jpg`}/>
                }
                </Link>
                </div>    
            </Box>}
            
            <Box width={rwidth} ml={marg}>
                <header>
                    {this.props.post.tags && showPrimaryTag && <div className="post-card-primary-tag-new"><Tags limit={1} post={this.props.post} visibility="public" autolink={true} permalink="/tag/:slug" /></div>}
                    <Link to={url}><h2 className={`post-card-title-new post-card-title-${fontSize}-new`}>{this.props.post.title}</h2></Link>
                    <span className="post-card-byline-date-new">
                        {(new Date(this.props.post.published_at)).toLocaleString(typeof window !== 'undefined'?window.navigator.userLanguage || window.navigator.language:'en-US',{year: 'numeric',month: 'long',day: 'numeric'})}
                    </span>                    
                </header>
            
           
                {this.props.hideExcerpt?null:<section className="post-card-excerpt-new"><p className={`post-card-excerpt-${fontSize}-new`}>{this.props.post.excerpt}</p></section>}
            </Box>

            </Flex>
            </Box>
            
            </React.Fragment>
        );
    }
}
    
export default PostCard;